import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Header from "../CommonComponent/Header";
import { FormattedMessage } from "react-intl";
import Cleave from "cleave.js/dist/cleave-react";
import {
  countryMobileLength,
  mobileNumberFormat,
} from "pr-utility/utils/utils";
import client from "pr-utility/config/client";
import * as actions from "./Actions";
import AlertMessage from "../CommonComponent/AlertMessage";
import { FLOW } from "../../flow";
import { getNextPage, onBlurEvent } from "pr-utility/utils/utils";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
import { postType } from "pr-utility/utils/postMessage";
import notification from "pr-utility/utils/notification";

class ActivateApp extends Component {
  constructor(props) {
    super(props);

    let code = "";
    if (props.Authorization && props.authCode) {
      code = props.authCode;
    } else if (props.history.location?.state?.code) {
      code = props.history.location.state.code;
    }

    this.state = {
      phoneNumber: "",
      phoneNumberEntered: false,
      country: client.PHONE_CODE,
      phoneLength: countryMobileLength[client.DEFAULT_COUNTRY_CODE],
      blocks: mobileNumberFormat[client.DEFAULT_COUNTRY_CODE].blocks,
      delimiters: mobileNumberFormat[client.DEFAULT_COUNTRY_CODE].delimiters,
      numericOnly: mobileNumberFormat[client.DEFAULT_COUNTRY_CODE].numericOnly,
      creditCardCleave: "",
      activateEnabled: false,
      showAlert: false,
      alertData: {},
      activationCode: code || "",
      activationCodeLength: 6,
      activationCodeEntered: false,
    };

    this.showAlertModal = this.showAlertModal.bind(this);
  }

  activate = () => {
    if (process.env.REACT_APP_COUNTRY === "AU") {
      this.activateAndRedirect();
    } else if (process.env.REACT_APP_COUNTRY === "US") {
      this.activateAndOTPVerify();
    }
  };

  activateAndRedirect = () => {
    this.activateApp(this.state.activationCode, () => {
      if (this.props.entityType === "DRIVER") {
        let nextPage = getNextPage(FLOW);
        this.props.history.push(nextPage);
      } else {
        this.truckCheckIn(() => {
          this.getTruckConfig(() => {
            this.getTruckDetail(() => {
              this.props.history.push("/home");
            });
          });
        });
      }
    });
  };

  activateAndOTPVerify = () => {
    this.activateApp(this.state.activationCode, () => {
      if (this.props.entityType === "DRIVER") {
        this.props.history.push("/mobile-verification");
      } else {
        this.truckCheckIn(() => {
          this.getTruckConfig(() => {
            this.getTruckDetail(() => {
              this.props.history.push("/home");
            });
          });
        });
      }
    });
  };

  saveActivationCode = (activationCode) => {
    this.props.dispatch(actions.saveActivationCode(activationCode));
  };

  truckCheckIn = async (callback) => {
    const { location, entityUid } = this.props;

    this.props.dispatch(actions.isLoading(true));

    let data = {
      location: {
        latitude: location.latitude,
        longitude: location.longitude,
        street: location.street,
        city: location.city,
        state: location.state,
        zipCode: location.zip,
      },
      truckInfo: {
        uid: entityUid,
      },
      driverInfo: {},
    };

    let response = await actions.truckCheckInAction(data);

    if (response && Number(response.status) === 200) {
      this.props.dispatch(actions.isLoading(false));

      callback();
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.checkInEntity);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  getTruckDetail = async (callback) => {
    const {
      entityUid,
      networkConfig,
      personalConfig,
      Authorization,
      DriverData,
      TruckData,
      AppConfig,
    } = this.props;

    this.props.dispatch(actions.isLoading(true));

    const response = await actions.getTruckDetailAction(entityUid);

    this.props.dispatch(actions.isLoading(false));

    if (response && Number(response.status) === 200) {
      if (response.data.data[0].truckInfo) {
        await this.props.dispatch(
          actions.truckDetailSuccess(response.data.data[0])
        );

        await this.props.dispatch(actions.SaveCheckinStatus(1));

        let logData = {
          type: "on_check_in",
          dto: {
            data: {
              Authorization,
              DriverData,
              TruckData,
              AppConfig,
              LocationUpdateConfig: {
                onJobLocationUpdateInterval:
                  personalConfig.onJobLocationUpdateInterval,
                idleLocationUpdateInterval:
                  personalConfig.idleLocationUpdateInterval,
                jobOfferDuration: personalConfig.jobOfferDuration,
                notMovingLocationUpdateInterval:
                  personalConfig.notMovingLocationUpdateInterval,
                distanceForOnTheWayMoving:
                  networkConfig.distanceForOnTheWayNotMoving || 0.2,
              },
            },
            error: false,
            errorMessage: "",
          },
        };
        notification.postMessage(logData);
        callback();
      } else {
        this.showAlertModal(false, {
          error: "An error has occurred.",
        });
        sendErrorLog({
          data: {
            error:
              "An error has occurred on getTruckDetail(activateapp) detail.",
          },
        });
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.getTruckDetail, callback);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  getTruckConfig = async (callback) => {
    this.props.dispatch(actions.isLoading(true));

    const response = await actions.getTruckConfigAction(this.props.entityUid);

    this.props.dispatch(actions.isLoading(false));

    if (response && Number(response.status) === 200) {
      if (response.data.data[0].appInfo) {
        this.props.dispatch(actions.truckConfigSuccess(response.data.data[0]));
        callback();
      } else {
        this.showAlertModal(false, {
          error: "An error has occurred.",
        });
        sendErrorLog({
          data: {
            error:
              "An error has occurred on getTruckConfig(activateapp) detail.",
          },
        });
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.getTruckConfig, callback);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  showAlertModal(netWorkError, response, callback, params) {
    let alertData;
    if (netWorkError) {
      if (response && response.code && response.code === "ECONNABORTED") {
        alertData = {
          header: <FormattedMessage id="common.timeout_header" />,
          body: <FormattedMessage id="common.timeout_message" />,
          rightText: <FormattedMessage id="common.cancel" />,
          leftOnClick: () => {
            this.setState({ showAlert: false });
            if (params && Array.isArray(params) && params.length > 1) {
              callback(...params);
            } else if (params) {
              callback(params);
            } else {
              callback();
            }
          },
          leftText: <FormattedMessage id="common.retry" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      } else {
        alertData = {
          header: "",
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.close" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      }
    } else {
      if (response) {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: response.data ? (
            response.data.error ? (
              response.data.error
            ) : (
              <FormattedMessage id="profile.error_msg" />
            )
          ) : (
            <FormattedMessage id="profile.error_msg" />
          ),
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      } else {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      }
    }
    this.setState({ showAlert: true, alertData });
  }

  activateApp = async (activationCode, callback) => {
    let data = { activationCode: activationCode };
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.activateApp(data);
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      this.setState({ activateEnabled: true });
      client.setAuthToken(response.data.accessToken);
      client.setEntityType(response.data.entityType);
      client.setEntityUid(response.data.entityUid);
      this.props.dispatch(actions.authDataSuccess(response.data));
      this.saveActivationCode(activationCode);
      const entityId = response.data.entityUid;
      const entityType = response.data.entityType;
      if (client.DRIVER_LOGIN === 1) {
        const body = {
          entityId: entityId,
          entityType: entityType,
        };
        actions.activateAppSuccess(body);
      }
      callback();
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.activateApp, [
        activationCode,
        callback,
      ]);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  onActivationCodeChange = (event) => {
    if (this.state.activationCode === event.target.rawValue) {
      return;
    }
    this.setState({ activateEnabled: false });
    if (
      event.target.value !== "" &&
      event.target.value.length !== 0 &&
      event.target.value.length === this.state.activationCodeLength &&
      !this.state.activationCodeEntered &&
      this.state.activationCode !== event.target.rawValue
    ) {
      this.setState({
        activationCode: event.target.rawValue,
        activationCodeEntered: true,
      });
    } else {
      this.setState({
        activationCode: event.target.rawValue,
        activationCodeEntered: false,
        activateEnabled: false,
      });
    }
  };
  loadConfigData = async () => {
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.loadConfig();
    if (response && Number(response.status) === 200) {
      this.props.dispatch(actions.fetchSuccessConfig(response.data.data[0]));
      this.props.dispatch(actions.isLoading(false));
      if (
        response.data &&
        response.data.data[0] &&
        response.data.data[0].config
      ) {
        let hostUrl = response.data.data[0].config["api.url.v4"];
        let hostUrlV3 = response.data.data[0].config["api.url"];
        client.setHostURL(hostUrl);
        client.setHostURLV3(hostUrlV3);
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.loadConfigData);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  loadConfigKeysData = async () => {
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.loadConfigKeys();
    if (response && Number(response.status) === 200) {
      this.props.dispatch(
        actions.fetchSuccessConfigKeys(response.data.data[0].config)
      );
      this.props.dispatch(actions.isLoading(false));
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.loadConfigKeysData);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  componentWillMount() {
    // clear data for logout
    this.props.dispatch(actions.resetHome());
    this.props.dispatch(actions.resetDriverData());
    this.props.dispatch(actions.resetAuthorization());
    this.props.dispatch(actions.resetTruck());
    this.props.dispatch(actions.resetEquipment());
    //this.loadConfigData();
    //this.loadConfigKeysData();
    this.loadConfigs();
  }

  loadConfigs = async () => {
    await this.loadConfigData();
    await this.loadConfigKeysData();

    const { AppConfig, Authorization, authCode } = this.props;

    let page = "activate-app";
    let code = null;
    if (Authorization && authCode) {
      code = authCode;
    } else if (this.state.activationCode && this.state.activationCode !== "") {
      code = this.state.activationCode;
    } else if (this.props.history.location?.state?.code) {
      code = this.props.history.location.state.code;
    }

    let data = {
      AppConfig,
      code,
    };

    if (data.code) {
      this.setState({
        activationCode: data.code,
        activationCodeEntered: true,
      });
    }
    let type = postType.APP_STATE;
    notification.sendMessageToNative(page, data, type);
  };

  render() {
    let page_header, page_title, wrapper_class, gray_class;

    if (process.env.REACT_APP_COUNTRY === "US") {
      page_header = <FormattedMessage id="activate_app.navbar_header" />;
      page_title = <FormattedMessage id="activate_app.header" />;
      wrapper_class = "parent_us";
      gray_class = "gray_bg";
    } else {
      page_header = <FormattedMessage id="activate_app.header" />;
      page_title = <FormattedMessage id="activate_app.title" />;
    }

    let next_button = <FormattedMessage id="activate_app.next_button" />;
    /*eslint-disable no-script-url*/
    return (
      <div className={`pg ${wrapper_class}`}>
        <Header title={page_header} />
        <AlertMessage
          showAlert={this.state.showAlert}
          {...this.state.alertData}
        />
        <div className="content">
          {process.env.REACT_APP_COUNTRY === "US" ? (
            <div>
              <div className="container">
                <div className="text-center">
                  <h2 className="pgTitle page_title">{page_title}</h2>
                  <div className="activate_message">
                    <FormattedMessage
                      tagName="div"
                      id="activate_app.description"
                      values={{
                        linebreak: <br />,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form mb10">
                <h2 className="listViewTitle fontSize18">
                  <FormattedMessage id="activate_app.label_enter_code" />
                </h2>
                <div className="listViewBox">
                  <ul className="type1">
                    <li>
                      <span className="text">
                        <Cleave
                          options={{
                            numericOnly: true,
                          }}
                          type="tel"
                          value={this.state.activationCode}
                          className="txtBox"
                          id="cardNum"
                          placeholder="Code"
                          autoComplete="off"
                          maxLength={this.state.activationCodeLength}
                          onChange={this.onActivationCodeChange}
                          onBlur={onBlurEvent}
                          required
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <h2 className="pgTitle">{page_title}</h2>
              <div className="form mb10">
                <div className="form-group">
                  <Cleave
                    options={{
                      numericOnly: true,
                    }}
                    type="tel"
                    value={this.state.activationCode}
                    className="form-control"
                    id="cardNum"
                    placeholder="Code"
                    autoComplete="off"
                    maxLength={this.state.activationCodeLength}
                    onChange={this.onActivationCodeChange}
                    onBlur={onBlurEvent}
                    required
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <footer className={`bottom_fix ${gray_class}`}>
          <div className="container clearfix pl-0 pr-0 text-center">
            <button
              className={
                "btn mb0 " +
                (this.state.activationCodeEntered ? "" : "disabled")
              }
              onClick={this.activate}
            >
              {next_button}
            </button>
          </div>
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    entityUid,
    entityType,
    code: authCode,
  } = state.Authorization.authorization;

  const { networkConfig, personalConfig } = state.DriverData;

  const location = state.Location;

  return {
    authCode,
    location,
    entityUid,
    entityType,
    networkConfig,
    personalConfig,
    DriverData: state.DriverData,
    TruckData: state.Truck,
    AppConfig: state.AppConfig,
    Provider: state.Provider,
    Driver: state.Driver,
    Authorization: state.Authorization.authorization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(actions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActivateApp)
);
