import React from "react";
import history from "pr-utility/history/history";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import App from "./app";
import * as Sentry from "@sentry/browser";
import SentryErrorBoundary from "./Sentry";
import { registerDefaultEvents } from "pr-utility/event-manager/event-manager";
import { lang } from "pr-utility/translations";
import client from "pr-utility/config/client";
import APIConfigs from "pr-utility/config/conf";
import "jquery/dist/jquery.min";
import "bootstrap/dist/js/bootstrap.min";
import "popper.js/dist/umd/popper.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "pr-utility/sass/ionicons.css";
import { loadCSS } from "pr-utility/sass";
import "pr-utility/sass/job-detail.scss";
import { store } from "./store";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import LaunchDarkly from "./LaunchDarkly";
import ReactDOM from "react-dom/client";

registerDefaultEvents();

APIConfigs.configureClient(client);

loadCSS();

let messages_en = Object.assign({}, lang);

const messages = {
  en: messages_en,
};

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: APIConfigs.SENTRY_DSN,
    environment: window.location.hostname.includes(...client.DEV_END_POINTS)
      ? "dev-web"
      : "prod-web",
  });
}

(async () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY,
    user: { key: "anonymous", anonymous: true },
  });

  root.render(
    <SentryErrorBoundary>
      <IntlProvider
        locale="en"
        messages={messages["en"]}
        textComponent={React.Fragment}
      >
        <Router history={history}>
          <LDProvider>
            <Provider store={store}>
              <App />
              <LaunchDarkly />
            </Provider>
          </LDProvider>
        </Router>
      </IntlProvider>
    </SentryErrorBoundary>
  );
})();

//registerServiceWorker();
